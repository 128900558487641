<template lang="pug">
#page-not-found
  error-page(empty-description="Страница не найдена" status-code="404")
    template(#errorMessage) Такой страницы не существует
    template(#errorDescription) Возможно, Вы воспользовались неисправной ссылкой или мы переместили страницу.

</template>

<script lang="ts">
import { defineComponent } from "vue";
import ErrorPage from "@/components/pages/error/ErrorPage.vue";

export default defineComponent({
  name: "PageNotFound",
  components: {
    ErrorPage,
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/main";

#page-not-found {
  @include adaptive-view;
}
</style>
